

<template>
     <div id="app">
        <h1 class="mainHeadline">...</h1>
    </div>
</template>

<script>

export default {
    name: 'App',    
    data() {
      return {
        user: null
      };
    },
    mounted () {
        document.title = "Benedikt Adams";
    }
};

</script>

<style>
html {
    background: #161617;
    color: #FEFEFE;
    font-family: 'Roboto', sans-serif;
}

.mainHeadline{
    display: inline-block;
    width: 90%;
    padding-left: 5rem;
    padding-top: 1.5rem;
}

.logoutButton {
    color: #7E7E7E;
    font-size: 18px;
    cursor: pointer;
    background: transparent;
    border: none;
}
</style>